import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { AuthService } from "./auth.service";
import { CharacterInfo, Student, StudentCurriculumInfo, Teacher, User, StudentStatusUpdate } from "src/types/users";
import { Observable, Subject, lastValueFrom } from "rxjs";
import { Course, Curriculum, GradeLevel } from "src/types/modules";
import { EquipablesService } from "./equipables.service";
import { Classroom } from "src/types/classrooms";


@Injectable({
    providedIn: "root"
})
export class UsersService {
    userSubject: Subject<User> = new Subject<User>();
    user: User | null;
    gettingUser: boolean = false;
    userEmitter: EventEmitter<User> = new EventEmitter<User>();

    curriculum: Curriculum | null;
    gettingCurriculum: boolean = false;
    curriculumEmitter: EventEmitter<Curriculum> = new EventEmitter<Curriculum>();
    currentClassroom: Classroom | null;
    currentClassroomId: string | null = null;
    currentTeacher: Teacher | null;

    fullCharInfo: CharacterInfo | null;
    gettingFullCharInfo: boolean = false;
    fullCharInfoEmitter: EventEmitter<CharacterInfo> = new EventEmitter<CharacterInfo>();

    userBackgroundImageUrl: string = "https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/backgrounds/forest.jpg";
    userBackgroundFocusMode: boolean = false; // Changes the user background to mostly white for focus in lessons
    userFocusModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    levelExpVales: number[] = [0, 100, 200, 300, 400, 525, 625, 725, 825, 925, 1075, 1175, 1275, 1375, 1475, 1600, 1800, 2000, 2200, 2400];

    requestUpgradeModel: EventEmitter<void> = new EventEmitter<void>();
    private lastCurrentlyOn: { name: string; id: string; description: string } | null = null;


    constructor(private authService: AuthService, private equipablesService: EquipablesService, private httpClient: HttpClient) {
        // Subscribe to user logging out to reset data
        authService.loginStatusChanged.subscribe((loggedIn: boolean) => {
            if (!loggedIn) {
                this.user = null;
                this.curriculum = null;
                this.userBackgroundImageUrl = "https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/backgrounds/forest.jpg";
                this.userBackgroundFocusMode = false;
            }
        })
    }

    async getUser(): Promise<User> {
        return new Promise(async (res, rej) => {
            if (this.gettingUser) {
                this.userEmitter.subscribe((user: User) => {
                    res(user);
                });
                return;
            }

            try {
                this.gettingUser = true;
                if (this.user) {
                    this.userEmitter.emit(this.user);
                    res(this.user);
                    this.gettingUser = false;
                    return;
                }

                const userId = this.authService.getTokenUserId();

                if (!userId) throw new Error('Token is invalid');

                const user = await lastValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/users/${userId}`)) as User;
                this.user = user;
                this.userSubject.next(user);
                this.userEmitter.emit(user);
                res(user);
                this.gettingUser = false;
            } catch (error: any) {
                rej(error);
            }
        });
    }

    async getMultipleStudents(studentIds: string[]) {
        const students = await lastValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/students/by/list`, { params: new HttpParams().set('studentIds', JSON.stringify(studentIds)) })) as Student[];
        return students;
    }

    async createStudent(newUserData: Partial<Student>, password: string) {
        try {
            const user = await lastValueFrom(this.httpClient.post(`${environment.apiBaseUrl}/students/`, { ...newUserData, password })) as User;
            this.user = user;
            return user;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async createStudentUnderTeacher(newUserData: Partial<Student>, password: string, teacherId: string) {
        try {
            const user = await lastValueFrom(this.httpClient.post(`${environment.apiBaseUrl}/students/`, { ...newUserData, password, teacherId })) as User;
            return user;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async createTeacher(newUserData: Partial<Teacher>, password: string) {
        try {
            const user = await lastValueFrom(this.httpClient.post(`${environment.apiBaseUrl}/teachers/`, { ...newUserData, password })) as User;
            this.user = user;
            this.userSubject.next(user);
            return user;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async getCurriculum(): Promise<Curriculum | null> {
        return new Promise(async (res, rej) => {
            if (this.gettingCurriculum) {
                this.curriculumEmitter.subscribe((curriculum: Curriculum) => {
                    res(curriculum);
                });
                return;
            }

            try {
                this.gettingCurriculum = true;
                if (this.curriculum) {
                    this.curriculumEmitter.emit(this.curriculum);
                    res(this.curriculum);
                    this.gettingCurriculum = false;
                    return;
                }

                let user = await this.getUser();

                const cur = await lastValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/curriculum/${user.curriculumInfo.curriculumId}`)) as Curriculum;
                this.curriculum = cur;
                this.curriculumEmitter.emit(cur);
                res(cur);
                this.gettingCurriculum = false;
            } catch (error: any) {
                rej(error);
                throw new Error(error);
            }
        });
    }

    async getFullCharInfo(): Promise<CharacterInfo | null> {
        return new Promise(async (res, rej) => {
            if (this.gettingFullCharInfo) {
                this.fullCharInfoEmitter.subscribe((fullCharInfo: CharacterInfo) => {
                    res(fullCharInfo);
                });
                return;
            }

            try {
                this.gettingFullCharInfo = true;
                if (this.fullCharInfo) {
                    this.fullCharInfoEmitter.emit(this.fullCharInfo);
                    res(this.fullCharInfo);
                    this.gettingFullCharInfo = false;
                    return;
                }

                let user = await this.getUser();

                let idList: string[] = [];
                let keysWithIds: string[] = [];
                Object.keys(user.characterInfo).forEach((piece) => {
                    let id = (user.characterInfo as any)[piece].id;
                    if (id) {
                        idList.push(id);
                        keysWithIds.push(piece);
                    }
                });

                const allEquipables = await this.equipablesService.getEquipableByList(idList);
                let fullCharInfo: any = Object.assign({}, user.characterInfo);

                for (let index in keysWithIds) {
                    let id = idList[index];
                    let piece = keysWithIds[index];
                    let foundMatch = allEquipables.find((e) => e._id === id);
                    if (!foundMatch) {
                        throw new Error("No match found for id: " + id);
                    }
                    fullCharInfo[piece] = foundMatch;
                    fullCharInfo[piece].id = foundMatch._id;
                }

                this.fullCharInfoEmitter.emit(fullCharInfo);
                res(fullCharInfo);
                this.gettingFullCharInfo = false;
            } catch (error: any) {
                rej(error);
                throw new Error(error);
            }
        });
    }

    async sendPasswordReset(email: string) {
        try {
            const res = await lastValueFrom(this.httpClient.post(`${environment.apiBaseUrl}/students/forgotPassword`, { email })) as Curriculum;
            return res;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async resetPassword(token: string, userId: string, newPassword: string) {
        try {
            const res = await lastValueFrom(this.httpClient.post(`${environment.apiBaseUrl}/students/resetPassword`, { token, userId, password: newPassword })) as Curriculum;
            return res;
        } catch (error: any) {
            throw new Error(error);
        }
    }

    async updateUserStatus(studentId: string, statusUpdates: StudentStatusUpdate): Promise<void> {
        if (!studentId) {
            console.error("No student ID provided for status update.");
            return;
        }

        const payload = {
            status: {
                online: statusUpdates?.online,
                currentlyOn: statusUpdates?.currentlyOn,
                lastCheckedIn: statusUpdates?.lastCheckedIn || new Date()
            }
        };

        try {
            const response = await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${studentId}/activity`, payload));
            console.log('Status update response:', response);
        } catch (error) {
            console.error('Status update error:', error);
        }
    }
    setUserOnline(studentId: string): void {
        this.updateUserStatus(studentId, { online: true });
    }

    setUserOffline(studentId: string): void {
        if (this.lastCurrentlyOn) {
            this.updateUserStatus(studentId, {
                online: false,
                currentlyOn: this.lastCurrentlyOn
            });
        } else {
            this.updateUserStatus(studentId, { online: false });
        }
    }

    updateLastCheckedIn(studentId: string): void {
        this.updateUserStatus(studentId, { online: true, lastCheckedIn: new Date() });
    }

    updateCurrentlyOn(studentId: string, name: string, id: string, description: string, online: boolean): void {
        this.lastCurrentlyOn = { name, id, description };
        this.updateUserStatus(studentId, {
            online: online,
            currentlyOn: { name, id, description }
        })
    }

    async updateStudentPassword(userId: string, oldPassword: string, newPassword: string) {
        await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${userId}/updatePassword`, { newPassword, oldPassword })) as User;
        return;
    }

    async updateStudentEmail(userId: string, newEmail: string) {
        const updatedUser = await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${userId}`, { email: newEmail })) as User;
        this.user = updatedUser;
        this.userSubject.next(updatedUser);
        return updatedUser;
    }

    async updateStudentCurriculum(userId: string, newCurInfo: StudentCurriculumInfo) {
        const updatedUser = await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${userId}`, { curriculumInfo: newCurInfo })) as User;
        this.user = updatedUser;
        this.userSubject.next(updatedUser);
        return updatedUser;
    }

    async setStudentCharacterInfo(userId: string, characterInfo: Partial<CharacterInfo>) {
        const updatedUser = await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${userId}`, { characterInfo: characterInfo })) as User;
        this.user = updatedUser;
        this.userSubject.next(updatedUser);
        return updatedUser;
    }

    async setStudentGrade(userId: string, gradeLevel: GradeLevel, curriculumId: string, subjectId: string) {
        const updatedUser = await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${userId}/setGrade`, { curriculumId, subjectId, gradeLevel })) as User;
        this.user = updatedUser;
        this.userSubject.next(updatedUser);
        return updatedUser;
    }

    async completeTutorial() {
        if (!this.user) return;
        const updatedUser = await lastValueFrom(this.httpClient.patch(`${environment.apiBaseUrl}/students/${this.user._id}`, { completedTutorial: true })) as User;
        this.user = updatedUser;
        this.userSubject.next(updatedUser);
        return updatedUser;
    }

    setUserFocusMode(focus: boolean) {
        this.userBackgroundFocusMode = focus;
        this.userFocusModeChange.emit(focus);
    }
}