<div [cLoading]="loading">
    <img class="logo" src="../../assets/images/cosmitt-gold.png" alt="Cosmitt logo">
    <div *ngIf="!passwordResetRequestSent" class="sub-section">
        <div class="header-text">
            <h2>Forgot your password?</h2>
            <p>Enter your email below to reset your password.</p>
            <p class="error-text" [hidden]="!errorText">- {{ errorText }}</p>
        </div>
        <div class="inputs">
            <c-input [(cModel)]="passwordResetEmailModel" type="email" label="Email address"></c-input>
        </div>
        <button (click)="requestPasswordReset()" class="sign-in">Request password reset</button>
        <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
    </div>
    <div *ngIf="passwordResetRequestSent" class="sub-section">
        <h2>Request Sent</h2>
        <p class="super-light">Check your email for your password reset link.</p>
        <button (click)="setPageContext(getPageContexts.Login)" class="secondary">Back to login</button>
    </div>
</div>