import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { UsersService } from "./users.service";
import { lastValueFrom } from "rxjs";
import { ToastsService } from "./toasts.service";


@Injectable({
    providedIn: "root"
})
export class StripeService {

    constructor(private httpClient: HttpClient, private usersService: UsersService, private toastsService: ToastsService) { }

    async openPurchaseSubscription(annualSub?: boolean) {
        try {
            const user = await this.usersService.getUser();
            if(user === null) return;

            const response: any = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + '/stripe/checkoutSessions', { userId: user._id, annual: annualSub }));

            window.location.href = response.url;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: error,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async createPortalSession() {
        try {
            const user = await this.usersService.getUser();
            if(user === null) return;

            const response: any = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + '/stripe/portalSessions', { userId: user._id }));

            window.location.href = response.sessionUrl;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: error,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }
}