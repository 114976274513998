<div *ngIf="label" class="label-and-action">
  <p class="caption disabled">{{ label }}</p>
  <p *ngIf="actionText" (click)="actionE.emit()" class="caption action">{{ actionText }}</p>
</div>

<p *ngIf="errorMessage && inputDirty" class="caption error"><strong>- {{ errorMessage }}</strong></p>

<div class="input-holder">
  <input [placeholder]="placeholder" [type]="showPassword ? 'text' : type" [disabled]="disabled || false" [ngModel]="_cModel"
    (ngModelChange)="onPrivateCModelChange($event)">

  <div *ngIf="type === 'password'" (click)="toggleShowPassword()" class="image-holder">
    <img [src]="showPassword ? '../../assets/icons/eye.svg' : '../../assets/icons/eye-closed.svg'" class="fa">
  </div>
</div>