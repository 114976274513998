import { Component } from "@angular/core";
import { Toast, ToastsService } from "src/services/toasts.service";


@Component({
    selector: 'toasts',
    templateUrl: './toasts.component.html',
    styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {
    toasts: (Toast & { removing?: boolean })[] = [];

    constructor(private toastService: ToastsService) { }

    ngOnInit() {
        this.toastService.toasts$.subscribe((toasts) => (this.toasts = toasts));
    }

    dismissToast(id: string) {
        // Add a fade-out transition
        const toast = this.toasts.find((t) => t.id === id);
        if (toast) {
            toast.removing = true;

            // Wait for the animation to complete before removing
            setTimeout(() => {
                this.toastService.removeToast(id);
            }, 300); // Match this duration to your CSS transition duration
        }
    }
}