import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { QuestionImage, RankingContent, RankingQuestion } from 'src/types/question';
import { LessonsService } from 'src/services/lessons.service';
import { GENERATING_SPEAKABLE_TEXT } from 'src/constants/shared';
import { SpeakableService } from 'src/services/speakable.service';
import { ToastsService } from 'src/services/toasts.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements AfterContentInit, OnChanges {
  _imageUrls: string[] = [];

  @Input() question: RankingQuestion;
  @Input() animateSentenceIn: boolean = false;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  playing: boolean = true;

  content: RankingContent[] = [];
  incorrectContent: boolean[];
  correctContent: boolean[];

  constructor(private lessonsService: LessonsService, private speakableService: SpeakableService, private toastsService: ToastsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;
    this.content = JSON.parse(JSON.stringify(this.question.data.content));

    let correct = true;

    while(correct) {
      this.lessonsService.shuffle(this.content);
      for(let i in this.content) {
        if(this.content[i].item !== this.question.data.content[i].item) {
          correct = false;
        }
      }
    }

    this.incorrectContent = [];
    this.correctContent = [];
    for(let i of this.content) {
      this.incorrectContent.push(false);
      this.correctContent.push(false);
    }
    
    setTimeout(() => {
      this.sentenceAnimateIn = true;
    }, 3500)

    if(this.question.data.images) {
      const imagePromises = this.question.data.images.map(async (image) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        return response.imageUrl;
      });

      Promise.all(imagePromises).then((urls) => {
          this._imageUrls = urls;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if(!this.speakableService.clickToSpeechActive) return;
    this.speakableService.playSpeakableByKey(url, text);
  }

  clickOnContent(contentIndex: number) {
    this.playSpeakable(this.content[contentIndex].speechData?.item, this.content[contentIndex].item);
  }

  dropRankingContent(event: CdkDragDrop<RankingContent[]>, content: any) {
    moveItemInArray(content, event.previousIndex, event.currentIndex);
  }

  checkRanking() {
    let correct = true;
    let numIncorrect = 0;

    for(let i in this.content) {
      if(this.content[i].item != this.question.data.content[i].item) {
        this.incorrectContent[i] = true;
        this.correctContent[i] = false;
        correct = false;
        numIncorrect++;
        setTimeout(() => {
          this.incorrectContent[i] = false;
        }, 2000);
      } else {
        this.incorrectContent[i] = false;
        this.correctContent[i] = true;
      }
    }

    if(correct) {
      this.playing = false;
      this.answeredCorrectly.emit();

      this.sentenceAnimateOut = true;

      setTimeout(() => {
        this.completedQuestion.emit();
      }, 1000);
    } else {
      this.answeredIncorrectly.emit();
    }
  }
}
