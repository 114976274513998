import { AfterContentInit, Component } from '@angular/core';
import { EquipablesService } from 'src/services/equipables.service';
import { ToastsService } from 'src/services/toasts.service';
import { Equipable, EquipableData, EquipableType, Gender } from 'src/types/equipables';
import { CharacterArmor, CharacterBack, CharacterEquipable, CharacterEyes, CharacterHead, CharacterHelmet, CharacterPet, HairColor, SkinColor } from 'src/types/users';


@Component({
  selector: 'app-equipable-creation',
  templateUrl: './equipable-creation.component.html',
  styleUrls: ['./equipable-creation.component.scss']
})
export class EquipableCreationPage implements AfterContentInit {

  currentType: EquipableType = 'helmet';

  equipables: Equipable[] = [];
  characterGender: Gender = 'male';
  characterSkinColor: SkinColor = 'light';
  characterHairColor: HairColor = 'darkBrown';
  characterHelmet: CharacterEquipable;
  characterEyes: CharacterEquipable;
  characterHead: CharacterEquipable;
  characterArmor: CharacterEquipable;
  characterBack: CharacterEquipable | undefined;
  characterPet: CharacterEquipable | undefined;

  editorOpen: boolean = false;
  editorCreating: boolean = false;
  selectedEquipable: Equipable | null = null;
  selectedEquipablePieces: any[];
  selectedPiece: any;
  selectedPieceType: string;
  selectedType: EquipableType;
  typeOptions: EquipableType[] = ['primary', 'secondary', 'helmet', 'eyes', 'head', 'armor', 'back', 'pet'];

  constructor(private equipablesService: EquipablesService, private toastsService: ToastsService) {

  }

  async ngAfterContentInit() {
    const fullHelmet = await this.equipablesService.getEquipableByName("Preppy");
    this.characterHelmet = { id: fullHelmet._id, name: fullHelmet.name, description: fullHelmet.description || "", maleSvgUrl: fullHelmet.default.wholeSvgUrl || "" };
    this.equipables = await this.equipablesService.getEquipablesByType(this.currentType);
  }

  async changeType(type: EquipableType) {
    this.currentType = type;
    this.equipables = await this.equipablesService.getEquipablesByType(this.currentType);
  }

  getTypeText(type: EquipableType) {
    switch (type) {
      case 'helmet': return 'Helmet';
      case 'eyes': return 'Eyes';
      case 'primary': return 'Primary item';
      case 'secondary': return 'Secondary item';
      case 'armor': return 'Armor';
      case 'pet': return 'Pet';
      default: return 'Unknown';
    }
  }

  getDisplayImage(equipable: Equipable) {
    const gender = this.getPiecesGenderField(this.characterGender);
    return (equipable as any)[gender].wholePhotoUrl;
  }

  getPiecesGenderField(gender: Gender): string {
    return gender === 'male' ? 'default' : 'female';
  }

  selectGender(gender: Gender) {
    this.characterGender = gender;
    if (this.selectedEquipable) {
      const piecesGender = this.getPiecesGenderField(gender);

      // Check if the gender option for this doesn't exist and create if needed
      if (!(this.selectedEquipable as any)[piecesGender]) {
        (this.selectedEquipable as any)[piecesGender] = JSON.parse(JSON.stringify(this.selectedEquipable.default));
      }
      this.selectEquipable(this.selectedEquipable);
    }
    if (this.selectedPiece) this.selectPiece(this.selectedPieceType);
  }

  selectEquipable(equipable: Equipable) {
    const piecesGender = this.getPiecesGenderField(this.characterGender);
    const fieldName = `character${equipable.type.charAt(0).toUpperCase() + equipable.type.slice(1)}`;
    (this as any)[fieldName] = equipable as any;
    this.selectedEquipable = equipable;
    this.selectedType = equipable.type;
    this.selectedEquipablePieces = Object.keys((equipable as any)[piecesGender].pieces);
    this.selectedPiece = ((equipable as any)[piecesGender].pieces as any)[this.selectedEquipablePieces[0]];
    this.selectedPieceType = this.selectedEquipablePieces[0];
    this.editorOpen = true;
  }

  selectPiece(piece: string) {
    if (!this.selectedEquipable) return;
    const piecesGender = this.getPiecesGenderField(this.characterGender);
    this.selectedPiece = ((this.selectedEquipable as any)[piecesGender].pieces as any)[piece];
    this.selectedPieceType = piece;
  }

  onFileUploaded(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      // Upload the formData using the API
    }
  }

  discardChanges() {
    this.editorOpen = false;

    setTimeout(() => {
      this.selectedEquipable = null;
      this.selectedPiece = null;
    }, 700);
  }

  async saveEquipable() {
    if (!this.selectedEquipable) return;
    let updates: Partial<Equipable> = {
      name: this.selectedEquipable.name,
      description: this.selectedEquipable.description,
      default: this.selectedEquipable.default
    }

    if (this.selectedEquipable.female) updates.female = this.selectedEquipable.female;

    try {
      const updatedEquipable = await this.equipablesService.updateEquipable(this.selectedEquipable._id, updates);
      this.selectedEquipable = updatedEquipable;
      this.toastsService.addToast({
        type: 'success',
        title: 'Update successful',
        description: 'Equipable has been successfully updated.',
        duration: 3000, // Auto-dismiss after 3 seconds
      });
    } catch (error: any) {
      this.toastsService.addToast({
        type: 'error',
        title: 'Server error',
        description: 'Equipable was not updated due to a server error.',
        duration: 3000, // Auto-dismiss after 3 seconds
      });
    }
  }

  newEquipable() {
    const newE = {
      name: "",
      description: "",
      type: this.currentType,
      default: {
        pieces: this.createEquipablePiecesByType(this.currentType)
      }
    } as Equipable; // This since we don't have an _id

    this.selectEquipable(newE);
  }

  async createEquipable() {
    if (!this.selectedEquipable) return;
    if (!this.selectedEquipable.name) {
      this.toastsService.addToast({
        type: 'error',
        title: 'Invalid data',
        description: 'Equipable must have a name.',
        duration: 3000, // Auto-dismiss after 3 seconds
      });

      return;
    };
    this.equipablesService.createEquipable(this.selectedEquipable);
  }

  createEquipablePiecesByType(type: EquipableType): EquipableData {
    switch (type) {
      case 'helmet': return {
        helmetHead: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        helmetBehindHead: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } }
      };
      case 'head': return { head: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } } };
      case 'eyes': return { eyes: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } } };
      case 'armor': return {
        torso: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        armFrontUpper: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        armFrontLower: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        armBackUpper: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        armBackLower: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        torsoLower: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        legFrontUpper: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        legFrontLower: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        legBackUpper: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        legBackLower: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } }
      };
      case 'back': return { back: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } } };
      case 'pet': return { pet: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } } };
      case 'primary': return {
        primaryBehindHand: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        primaryInFrontOfHand: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } }
      };
      case 'secondary': return {
        secondaryBehindHand: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } },
        secondaryInFrontOfHand: { svgUrl: "", size: { width: 0, height: 0 }, offset: { x: 0, y: 0, r: 0 } }
      };
    }
  }
}
