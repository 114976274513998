<div id="sentence-area" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">

    <!-- Handling old spelling game mode format -->
    <div *ngIf="question.type === 'spellingMultiStep'">
        <h3>Outdated Game Format</h3>
        <p>This game has an outdated format. Please update format in order for game to be playable.</p>
    </div>

    <!-- Review instruction and example words -->
    <div *ngIf="gameState === 0 && question.type !== 'spellingMultiStep'" class="intro">
        <div class="review-words">
            <h1 *ngFor="let word of introReviewWords; let i = index;">{{ word.word }}</h1>
        </div>

        <button (click)="startLesson()">Start</button>
    </div>



    <!-- Phoneme game mode -->
    <div *ngIf="gameState === 1 && question.type !== 'spellingMultiStep'" class="phoneme-game-container"  (dragover)="onDragOver($event)" (drop)="onDropToBank($event)">
        <div (click)="playWord()" class="word-speakable">
            <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.1993 29.3056C7.73522 31.3056 4.25317 29.2745 2.75317 26.6764C1.25317 24.0783 1.23522 20.0472 4.69932 18.0472C6.06337 17.2597 7.33245 16.4571 8.51117 15.6495M11.1993 29.3056C12.5634 28.518 13.893 27.8203 15.1817 27.2032M11.1993 29.3056L16.3065 38.1515C16.3759 38.2717 16.5437 38.2864 16.6329 38.1801L20.1224 34.0241C20.1765 33.9596 20.1845 33.8683 20.1424 33.7955L16.0909 26.778M8.51117 15.6495C16.6027 10.1063 20.4364 4.33397 21.5073 1.64522C21.5616 1.50882 21.7379 1.47021 21.8409 1.57481C23.8702 3.63506 25.8446 6.29824 27.2995 8.46309M8.51117 15.6495C8.51117 15.6495 10.7027 18.0453 12.4527 21.0764C14.2027 24.1075 15.1817 27.2032 15.1817 27.2032M15.1817 27.2032C15.4871 27.057 15.7902 26.9153 16.0909 26.778M16.0909 26.778C24.5009 22.9384 31.043 22.5479 33.808 22.9504C33.9533 22.9716 34.0747 22.8382 34.0356 22.6967C33.2661 19.9092 31.9469 16.8677 30.7995 14.5253M27.2995 8.46309C28.1099 9.6689 28.7592 10.7201 29.1669 11.4264C29.5747 12.1327 30.1605 13.2205 30.7995 14.5253M27.2995 8.46309C28.6154 7.31848 31.6971 5.8087 33.4971 8.92639C35.2971 12.0441 32.4487 13.958 30.7995 14.5253" stroke="black" stroke-width="3"/></svg>
        </div>
        <div class="phoneme-slots">
            <div *ngFor="let phonemeSlot of correctPhonemeList; let i = index;" (click)="selectSlot(i)" class="phoneme-slot" (dragover)="onDragOver($event)" (drop)="onDrop($event, i)" [draggable]="phonemeSlot !== undefined"
            (dragstart)="onDragStart($event, i, true)" [class.active]="selectedPhonemeIndex >= 0 || selectedSlotIndex === i" [class.correct]="phonemeSlot?.correct" [class.incorrect]="phonemeSlot?.incorrect">
                <svg [class.hidden]="!phonemeSlot?.phoneme" width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 14.5V18.5M7 9.5V22.5M12.5 12.5V19.5M18 8.5V26.5M23.5 1.5V31.5M29 11.5V22.5M34.5 14.5V18.5" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
            </div>
        </div>
        <div class="phoneme-list" >
            <div *ngFor="let phoneme of phonemeBankList; let i = index;" (click)="playPhonemeFromBank(i)" draggable="true" (dragstart)="onDragStart($event, i)" class="phoneme" [class.active]="i === selectedPhonemeIndex">
                <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 14.5V18.5M7 9.5V22.5M12.5 12.5V19.5M18 8.5V26.5M23.5 1.5V31.5M29 11.5V22.5M34.5 14.5V18.5" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
            </div>
        </div>
        <div>
            <button (click)="checkPhonemeAnswers()">Submit</button>
        </div>
    </div>



    <!-- Type the word game mdoe -->
    <div *ngIf="gameState === 2 && question.type !== 'spellingMultiStep'" class="spell-game-container">
        <div (click)="playWord()" class="word-speakable">
            <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.1993 29.3056C7.73522 31.3056 4.25317 29.2745 2.75317 26.6764C1.25317 24.0783 1.23522 20.0472 4.69932 18.0472C6.06337 17.2597 7.33245 16.4571 8.51117 15.6495M11.1993 29.3056C12.5634 28.518 13.893 27.8203 15.1817 27.2032M11.1993 29.3056L16.3065 38.1515C16.3759 38.2717 16.5437 38.2864 16.6329 38.1801L20.1224 34.0241C20.1765 33.9596 20.1845 33.8683 20.1424 33.7955L16.0909 26.778M8.51117 15.6495C16.6027 10.1063 20.4364 4.33397 21.5073 1.64522C21.5616 1.50882 21.7379 1.47021 21.8409 1.57481C23.8702 3.63506 25.8446 6.29824 27.2995 8.46309M8.51117 15.6495C8.51117 15.6495 10.7027 18.0453 12.4527 21.0764C14.2027 24.1075 15.1817 27.2032 15.1817 27.2032M15.1817 27.2032C15.4871 27.057 15.7902 26.9153 16.0909 26.778M16.0909 26.778C24.5009 22.9384 31.043 22.5479 33.808 22.9504C33.9533 22.9716 34.0747 22.8382 34.0356 22.6967C33.2661 19.9092 31.9469 16.8677 30.7995 14.5253M27.2995 8.46309C28.1099 9.6689 28.7592 10.7201 29.1669 11.4264C29.5747 12.1327 30.1605 13.2205 30.7995 14.5253M27.2995 8.46309C28.6154 7.31848 31.6971 5.8087 33.4971 8.92639C35.2971 12.0441 32.4487 13.958 30.7995 14.5253" stroke="black" stroke-width="3"/></svg>
        </div>

        <input class="typing-input" [(ngModel)]="typingModeModel" placeholder="Type word here..." [class.correct]="typingCorrect" [class.incorrect]="typingIncorrect">

        <div>
            <button (click)="checkTypingAnswer()">Submit</button>
        </div>
    </div>

</div>
