import { Injectable } from "@angular/core";
import { GENERATING_SPEAKABLE_TEXT } from "src/constants/shared";
import { ToastsService } from "./toasts.service";
import { environment } from "src/environment/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { firstValueFrom, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SpeakableService {

    // This controls whether the user is actively trying to use our on-screen reader.
    clickToSpeechActive: boolean = false;

    // Variables to control current audio playing
    currentSpeakableUrl: string = "";
    currentlySpeaking: boolean = false;
    currentAudio: HTMLAudioElement;

    // Caching for speakables and phonemes
    private speakableCache = new Map<string, string>();
    private phonemeCache = new Map<string, string>();

    constructor(private toastsService: ToastsService, private httpClient: HttpClient) { }

    toggleClickToSpeechActive() {
        this.clickToSpeechActive = !this.clickToSpeechActive;
        if (!this.clickToSpeechActive && this.currentAudio) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
            this.currentlySpeaking = false;
        }
    }

    async playSpeakableByKey(speakableKey?: string, textToPlay?: string): Promise<Subject<void> | void> {
        // Handle a popup or something if we're still generating this speakable
        if (!speakableKey || speakableKey === GENERATING_SPEAKABLE_TEXT) {
            try {
                if (window.speechSynthesis && textToPlay) {
                    let utterance = new SpeechSynthesisUtterance(textToPlay.replace(/(<([^>]+)>)/ig, ''));
                    speechSynthesis.speak(utterance);
                } else {
                    // Can't play without our speech, let them know it is generating
                    this.toastsService.addToast({
                        type: 'warning',
                        title: 'Still generating',
                        description: "The speech you requested is currently being generated. Try again in a couple minutes.",
                        duration: 3000, // Auto-dismiss after 3 seconds
                    });
                }
            } catch (error) {
                // Toast for the error
                this.toastsService.addToast({
                    type: 'error',
                    title: 'Speech Error',
                    description: "There was a problem trying to play this speech.",
                    duration: 3000, // Auto-dismiss after 3 seconds
                });
            }
            return;
        }

        // Check cache for speakable URL
        if (this.speakableCache.has(speakableKey)) {
            this.currentSpeakableUrl = this.speakableCache.get(speakableKey)!;
        } else {
            let queryParams = new HttpParams();
            if (textToPlay) queryParams = queryParams.set("text", textToPlay);

            const response = await firstValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/speakables/${encodeURIComponent(speakableKey)}`, { params: queryParams })) as any;
            this.currentSpeakableUrl = response.resourceUrl;

            // Cache the response URL
            this.speakableCache.set(speakableKey, this.currentSpeakableUrl);
        }

        return this.playAudio(this.currentSpeakableUrl);
    }

    async getAndPlayPhoneme(phoneme: string): Promise<Subject<void> | void> {
        // Check cache for phoneme URL
        if (this.phonemeCache.has(phoneme)) {
            this.currentSpeakableUrl = this.phonemeCache.get(phoneme)!;
        } else {
            const response = await firstValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/phonemes/${phoneme}.mp3`)) as any;
            this.currentSpeakableUrl = response.resourceUrl;

            // Cache the response URL
            this.phonemeCache.set(phoneme, this.currentSpeakableUrl);
        }

        return this.playAudio(this.currentSpeakableUrl);
    }

    private playAudio(url: string): Subject<void> | void {
        // Check if we are still speaking
        if (this.currentlySpeaking) {
            if (this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            return;
        }

        const speakingSub = new Subject<void>();

        // Start speaking our audio
        this.currentlySpeaking = true;
        this.currentAudio = new Audio(url);
        this.currentAudio.play();
        this.currentAudio.onended = () => {
            this.currentlySpeaking = false;
            speakingSub.next();
            speakingSub.complete();
        };

        return speakingSub;
    }

    // Get an mp3 file from the URL provided and play audio
    // async getAndPlayPhoneme(phoneme: string) {
    //     if(this.currentAudio) this.currentAudio.pause();
    //     this.currentlySpeaking = false;
    //     const response = await firstValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/speakables/phonemes%2F${phoneme}.mp3`)) as any;
    //     this.currentSpeakableUrl = response.imageUrl;
    //     this.currentAudio = new Audio(this.currentSpeakableUrl);

    //     // Start speaking our audio
    //     this.currentlySpeaking = true;
    //     this.currentAudio.play();
    //     this.currentAudio.onended = () => {
    //         this.currentlySpeaking = false;
    //     }
    //     // Check if user is trying to play a different sounds than what is possibly playing. If so, stop current audio and play new audio
    //     // if(phoneme !== this.currentSpeakableUrl) {
    //     //     if(this.currentAudio) this.currentAudio.pause();
    //     //     this.currentlySpeaking = false;
    //     //     this.currentSpeakableUrl = phoneme;
    //     //     this.currentAudio = new Audio(`${environment.aws.phonemeBucket}/${phoneme.replace(':', '')}.mp3`);
    //     // } else if(this.currentlySpeaking) {
    //     //     if(this.currentAudio) this.currentAudio.pause();
    //     //     this.currentlySpeaking = false;
    //     //     return;
    //     // }

    //     // // Return if we are still speaking
    //     // if(this.currentlySpeaking) return;

    //     // // Start speaking our audio
    //     // this.currentlySpeaking = true;
    //     // this.currentAudio.play();
    //     // this.currentAudio.onended = () => {
    //     //     this.currentlySpeaking = false;
    //     // }
    // }
}
