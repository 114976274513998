import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CharacterAssetsCacheService {
  private svgCache = new Map<string, SafeHtml>();
  private inFlightRequests = new Map<string, any>(); // or Map<string, Observable<SafeHtml>>

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  getSvgAsset(svgUrl: string): Observable<SafeHtml> {
    // 1) Check the cache
    const cached = this.svgCache.get(svgUrl);
    if (cached) {
      return of(cached); // Return an Observable of the cached value
    }

    // 2) Check if we already have an in-flight request for this URL
    if (this.inFlightRequests.has(svgUrl)) {
      return this.inFlightRequests.get(svgUrl);
    }

    // 3) Create an Observable that fetches and sanitizes the asset
    const request$ = this.httpClient.get(svgUrl, { responseType: 'text' }).pipe(
      map(svgText => this.sanitizer.bypassSecurityTrustHtml(svgText)),
      // Store in cache when done
      map(safeSvg => {
        this.svgCache.set(svgUrl, safeSvg);
        return safeSvg;
      }),
      // Use shareReplay so that multiple subscribers share the same HTTP request
      shareReplay(1)
    );

    // Store the request in inFlightRequests until it completes
    this.inFlightRequests.set(svgUrl, request$);

    // When the request completes or errors, remove it from inFlightRequests
    request$.subscribe({
      next: () => this.inFlightRequests.delete(svgUrl),
      error: () => this.inFlightRequests.delete(svgUrl)
    });

    // 4) Return the new request Observable
    return request$;
  }
}
