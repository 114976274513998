import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SpeakableService } from 'src/services/speakable.service';
import { GroupingGroupItem, GroupingQuestion } from 'src/types/question';

@Component({
  selector: 'app-grouping',
  templateUrl: './grouping.component.html',
  styleUrls: ['./grouping.component.scss']
})
export class GroupingComponent implements AfterContentInit, OnChanges {

  @Input() question: GroupingQuestion;
  @Input() animateSentenceIn: boolean = false;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  answeredCurrentItemIncorrectly: boolean = false; // Used to control debouncing of user clicking the same incorrect answer over and over
  currentItemIndex = 0;

  displayGroups: any = [[], [], [], [], []];
  correctCount = 0;
  incorrectCount = 0;
  
  groupsBank: GroupingGroupItem[] = [];
  currentItem: any;

  groupSuccessColor = [false, false, false, false];
  tempGroupErrorDisplay = [false, false, false, false];

  constructor(private speakableService: SpeakableService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;
    let bank: GroupingGroupItem[] = [];
    if(!this.question.data.groups) {

    }
    for(let group of this.question.data.groups) {
      bank.push(...group.content);
    }
    this.shuffle(bank);
    this.groupsBank = bank;
    this.currentItem = this.groupsBank[0];

    setTimeout(() => {
      this.sentenceAnimateIn = true;
    }, 3500)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
      // Return if user isn't actively in text to speech mode
      if(!this.speakableService.clickToSpeechActive) return;
      this.speakableService.playSpeakableByKey(url, text);
  }

  moveToGroup(groupIndex: number) {
    let correct = false;
    for(let i in (this.question as GroupingQuestion).data.groups[groupIndex].content) {
      if((this.question as GroupingQuestion).data.groups[groupIndex].content[i].item == this.groupsBank[this.currentItemIndex].item) {
        correct = true;
        break;
      }
    }

    if(correct) {
      if(!this.answeredCurrentItemIncorrectly) {
        this.correctCount++;
      
        //Show progress for every term grouped correctly
        this.answeredCorrectly.emit({
          // scoreDelta: 20/this.groupsBank.length,
          correctAnswer: true,
        });
      }

      this.displayGroups[groupIndex].push(this.groupsBank[this.currentItemIndex]);
      this.currentItemIndex++;
      this.currentItem = this.groupsBank[this.currentItemIndex];
      this.answeredCurrentItemIncorrectly = false;

      this.groupSuccessColor[groupIndex] = true;
      setTimeout(() => {
        this.groupSuccessColor[groupIndex] = false;
      }, 1000);

      if(this.currentItemIndex >= this.groupsBank.length) {
        setTimeout(() => {
          this.sentenceAnimateOut = true;
          setTimeout(() => {
            this.completedQuestion.emit();
            setTimeout(() => {
              this.displayGroups = [[], [], [], []];
              this.groupsBank = [];
              this.currentItemIndex = 0;
            }, 1500);
          }, 1000);
        }, 1000);
      } else {
        //this.ttsService.queueSpeakableProperty(this.groupsBank[this.currentItemIndex], "item");
      }
    } else {
      this.tempGroupErrorDisplay[groupIndex] = true;
      setTimeout(() => {
        this.tempGroupErrorDisplay[groupIndex] = false;
      }, 1000);

      if(this.answeredCurrentItemIncorrectly) return; // Don't allow user to answer incorrectly over and over if repeatedly clicking

      this.incorrectCount++;
      this.answeredCurrentItemIncorrectly = true;
      this.answeredIncorrectly.emit({
        correctAnswer: false
      });
    }
  }

  shuffle (t: GroupingGroupItem[]) {
    let last = t.length;
    let n
    while (last > 0) {
      n = this.rand(last);
      this.swap(t, n, --last);
    }
  }

  rand = (n: any) => Math.floor(Math.random() * n)

  swap (t: Array<any>, i: any, j: any) {
    let q = t[i];
    t[i] = t[j];
    t[j] = q;
    return t;
  }

}
