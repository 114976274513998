import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GAME_CONSTANTS } from "src/constants/game-modes";
import { SpeakableService } from "src/services/speakable.service";
import { StudentLessonsService } from "src/services/studentLessons.service";
import { UsersService } from "src/services/users.service";
import { Lesson } from "src/types/modules";
import { Question } from "src/types/question";
import { StudentLesson, StudentLessonRecord } from "src/types/studentLesson";
import { User } from "src/types/users";


@Component({
    selector: 'default-lesson',
    templateUrl: './default-lesson.component.html',
    styleUrls: ['./default-lesson.component.scss']
})
export class DefaultLessonComponent implements OnInit {

    @Input() lesson: Lesson;
    @Input() studentLesson: StudentLesson;
    @Input() grade: number;
    @Output() gradeChange: EventEmitter<number> = new EventEmitter<number>();

    @Output() completedLesson: EventEmitter<string> = new EventEmitter<string>();

    user: User;

    currentQuestion: Question;
    currentRecord: StudentLessonRecord | null;
    currentQuestionIndex: number = 0;

    numQuestions: number = 4; // How many questions the user has to answer to finish the lesson. Default is 20.
    numQuestionsCompleted: number = 0; // How many questions the user has completed. Lesson ends when this equals numQuestions.
    correctAnswers: number = 0; // How many questions the student has answered correctly.
    incorrectAnswers: number = 0; // How many questions the student has answered incorrectly.
    progress: number = 0; // Percentage user has completed the lesson.
    streakMeter: number = 0;
    streakShake: boolean = false;
    streakThud: boolean = false;

    outroActive: boolean = false;
    outroOpen: boolean = false;

    questionsRandomized: boolean = false; // If true, grabs random question, else goes linearly
    questionsIndexAnswered: number[] = []; // An array of the index's of questions that have already been answered by the user

    constructor(
        private usersService: UsersService,
        private studentLessonsService: StudentLessonsService,
        private speakableService: SpeakableService
    ) {
        this.usersService.setUserFocusMode(true);
    }

    ngOnInit(): void {
        this.currentRecord = this.studentLesson.records[0];
        this.currentQuestion = this.lesson.questions[0];
        this.numQuestions = GAME_CONSTANTS[this.currentQuestion.type].questionsPerLesson;
        this.numQuestionsCompleted = this.currentRecord.data.questionsCompleted;
        this.correctAnswers = this.currentRecord.data.correctAnswers;
        this.incorrectAnswers = this.currentRecord.data.incorrectAnswers;
        this.progress = this.numQuestionsCompleted / this.numQuestions * 100;
    }

    resetPage() {
        this.numQuestionsCompleted = 0;
        this.correctAnswers = 0;
        this.incorrectAnswers = 0;
        this.currentQuestionIndex = 0;
        this.progress = 0;
        this.questionsIndexAnswered = [];
        this.currentRecord = null;
    }

    calcGrade() {
        if(this.correctAnswers + this.incorrectAnswers === 0) return 0;
        return this.correctAnswers / (this.correctAnswers + this.incorrectAnswers) * 100;
    }

    answeredCorrectly() {
        this.streakThud = true;
        setTimeout(() => {
            this.streakThud = false;
          }, 700);
          
        this.streakMeter += 1;
        if(!this.studentLesson || !this.currentRecord) return;
        this.correctAnswers++;
        this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, { 
            data: {
                correctAnswers: this.correctAnswers,
                incorrectAnswers: this.incorrectAnswers,
                questionsCompleted: this.numQuestionsCompleted
            }
        });
    }

    answeredIncorrectly() {
        if(!this.studentLesson || !this.currentRecord) return;
        this.streakMeter = 0;
        this.streakShake = true;
        this.incorrectAnswers++;
        this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, { 
            data: {
                correctAnswers: this.correctAnswers,
                incorrectAnswers: this.incorrectAnswers,
                questionsCompleted: this.numQuestionsCompleted
            }
        });
    }

    async completedQuestion() {
        if(!this.studentLesson || !this.currentRecord) return;
        const percentageIncrease = 1 / this.numQuestions * 100;
        if(this.progress + percentageIncrease > 100) this.progress = 100;
        else this.progress += percentageIncrease;
        this.numQuestionsCompleted++;

        const grade = this.calcGrade();
        this.currentRecord.grade = grade;

        let recordUpdates: Partial<StudentLessonRecord> = {
            grade: grade,
            data: {
                correctAnswers: this.correctAnswers,
                incorrectAnswers: this.incorrectAnswers,
                questionsCompleted: this.numQuestionsCompleted
            }
        };

        // Feed the student another question if they haven't completed enough questions
        if(this.numQuestionsCompleted < this.numQuestions) {
            this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, recordUpdates);
            this.nextQuestion();
            return;
        }

        recordUpdates.completed = true;
        recordUpdates.completedOn = new Date();

        const response: { nextLessonId: string, updatedStudentLesson: StudentLesson } = await this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, recordUpdates);

        this.outroActive = true;
        this.outroOpen = true;

        this.completedLesson.emit(response.nextLessonId);
    }

    nextQuestion() {
        this.currentQuestionIndex++;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
        this.numQuestions = GAME_CONSTANTS[this.currentQuestion.type].questionsPerLesson;
        this.streakShake = false;
    }

    isClickToSpeechActive() {
        return this.speakableService.clickToSpeechActive;
    }

    playSpeakable(url: string | undefined, text?: string) {
        // Return if user isn't actively in text to speech mode
        if (!this.speakableService.clickToSpeechActive) return;
        this.speakableService.playSpeakableByKey(url, text);
    }

    
}