<div class="toast-container">
    <div *ngFor="let toast of toasts" class="toast" [ngClass]="{ fadeOut: toast.removing }" [attr.data-id]="toast.id">
        <svg class="close-btn" (click)="dismissToast(toast.id)" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.18629 25.5204L25.5208 2.18585M1.83274 1.8323L25.1673 25.1668" stroke="black" stroke-width="3" stroke-linecap="round"/></svg>
        <img *ngIf="toast.type === 'info'" class="icon" src="../../assets/icons/alerts/info.svg">
        <img *ngIf="toast.type === 'success'" class="icon" src="../../assets/icons/alerts/success.svg">
        <img *ngIf="toast.type === 'warning'" class="icon" src="../../assets/icons/alerts/warning.svg">
        <img *ngIf="toast.type === 'error'" class="icon" src="../../assets/icons/alerts/error.svg">
        <div class="text">
            <h6 class="toast-title">{{ toast.title }}</h6>
            <p class="toast-description">{{ toast.description }}</p>
            <div class="toast-actions" *ngIf="toast.actions?.length">
                <button *ngFor="let action of toast.actions" (click)="action.callback()">
                    {{ action.label }}
                </button>
            </div>
        </div>
    </div>
</div>