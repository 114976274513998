import { Component, EventEmitter, Output } from "@angular/core";
import { PageContext } from "../login.component";
import { UsersService } from "src/services/users.service";


@Component({
    selector: "forgot-password-step",
    templateUrl: "./forgot-password-step.component.html",
    styleUrls: ["./forgot-password-step.component.scss"]
})
export class ForgotPasswordStepComponent {
    
    @Output() changePageContext: EventEmitter<PageContext> = new EventEmitter<PageContext>();

    errorText: string = "";
    loading: boolean = false;

    // Forgot password variables
    passwordResetRequestSent: boolean = false;
    passwordResetSuccess: boolean = false;
    passwordResetEmailModel: string;

    public get getPageContexts(): typeof PageContext {
        return PageContext;
    }

    constructor(private usersService: UsersService) {
    }

    async requestPasswordReset() {
        try {
            this.errorText = "";
            this.loading = true;
            await this.usersService.sendPasswordReset(this.passwordResetEmailModel);
            this.passwordResetRequestSent = true;
            this.loading = false;
        } catch(error: any) {
            this.errorText = error.message;
            this.loading = false;
        }
    }

    setPageContext(pageContext: PageContext) {
        this.changePageContext.emit(pageContext);
    }
}