import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { LessonsService } from 'src/services/lessons.service';
import { SpeakableService } from 'src/services/speakable.service';
import { ToastsService } from 'src/services/toasts.service';
import { Question, SpellingWord, SpellingWordPhoneme } from 'src/types/question';

type PhonemeWithCorrect = SpellingWordPhoneme & { correct: boolean, incorrect: boolean }


@Component({
  selector: 'spelling',
  templateUrl: './spelling.component.html',
  styleUrls: ['./spelling.component.scss']
})
export class SpellingComponent implements AfterContentInit, OnChanges {

  @Input() question: Question;
  @Input() animateSentenceIn: boolean = false;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  gameState: number = 0; // 0 is intro and showing examples, 1 is phoneme game, 2 is typing the word

  introReviewWords: SpellingWord[] = []; // 3 words out of the bank to display at the beginning

  words: SpellingWord[] = []; // all of the words in the lesson
  currentWordIndex: number = 0;
  correctPhonemeList: (PhonemeWithCorrect | undefined)[];
  selectedPhonemeIndex: number = -1;
  selectedSlotIndex: number = -1;
  phonemeBankList: SpellingWordPhoneme[];
  typingModeModel: string = "";
  typingCorrect: boolean = false;
  typingIncorrect: boolean = false;
  wordsCorrect: boolean[];

  mousePosX: number;
  mousePosY: number;

  checkingAnswers: boolean = false; // For controlling interaction after checking answers and moving to next question/word

  constructor(private speakableService: SpeakableService, private toastsService: ToastsService, private lessonsService: LessonsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;

    // Set words and shuffle for randomness
    this.words = JSON.parse(JSON.stringify(this.question.data.words));
    this.lessonsService.shuffle(this.words);

    // Push 1st 3 words into introReviewWords
    this.introReviewWords.push(this.words[0]);
    if(this.words.length > 1) this.introReviewWords.push(this.words[1]);
    if(this.words.length > 2) this.introReviewWords.push(this.words[2]);

    // Create an array to reference to see which words the user already knows
    this.wordsCorrect = Array(this.words.length);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  startLesson() {
    console.log("Starting lesson")
    // Animate the current game state out
    this.sentenceAnimateIn = false;
    this.sentenceAnimateOut = true;

    setTimeout(() => {
      // Start the user on the first word in the array with the phoneme game mode
      this.currentWordIndex = 0;
      this.gameState = 1;
      this.correctPhonemeList = new Array(this.words[this.currentWordIndex].correctPhonemes.length);
      this.phonemeBankList = JSON.parse(JSON.stringify([...this.words[this.currentWordIndex].correctPhonemes, ...this.words[this.currentWordIndex].falsePhonemes]));
      this.lessonsService.shuffle(this.phonemeBankList);

      console.log(this.correctPhonemeList)

      // Animate the new question in
      setTimeout(() => {
        this.sentenceAnimateOut = false;
        this.sentenceAnimateIn = true;
      }, 300);
    }, 1000);
  }

  nextWord() {
    // Animate the current question out
    this.sentenceAnimateIn = false;
    this.sentenceAnimateOut = true;

    setTimeout(() => {
      // Setup for the new word and its game mode
      let itterations = 0;
      let newIndex = Math.floor(Math.random() * this.words.length);
      let foundNewWord = false;
      this.checkingAnswers = false;
      this.typingCorrect = false;
      this.typingIncorrect = false;

      // Ensure they haven't already gotten this word correct. If they have, go to next
      while(itterations < this.words.length) {
        itterations++;
        if(this.wordsCorrect[newIndex]) {
          newIndex++;
          if(newIndex >= this.words.length) newIndex = 0;
          continue;
        }

        // Set up game
        foundNewWord = true;
        this.currentWordIndex = newIndex;
        this.gameState = 1;
        this.correctPhonemeList = new Array(this.words[this.currentWordIndex].correctPhonemes.length);
        this.phonemeBankList = JSON.parse(JSON.stringify([...this.words[this.currentWordIndex].correctPhonemes, ...this.words[this.currentWordIndex].falsePhonemes]));
        this.lessonsService.shuffle(this.phonemeBankList);
        break;
      }

      if(!foundNewWord) {
        this.completedQuestion.emit();
      }

      // Animate the new question in
      setTimeout(() => {
        this.sentenceAnimateOut = false;
        this.sentenceAnimateIn = true;
      }, 300);
    }, 1000);
  }

  checkPhonemeAnswers() {
    if(this.checkingAnswers) return;

    this.checkingAnswers = true;
    let answerIsCorrect = true;

    for(let index in this.correctPhonemeList) {
      const phoneme = this.correctPhonemeList[index];
      if(!phoneme || phoneme?.phoneme !== this.words[this.currentWordIndex].correctPhonemes[index].phoneme) {
        if(phoneme) phoneme.incorrect = true;
        answerIsCorrect = false;
        continue;
      }
      phoneme.correct = true;
    }

    // Give the student a couple of seconds to see their wrong answer and go to next word.
    setTimeout(() => {
      if(answerIsCorrect) {
        // Animate the current question out
        this.sentenceAnimateIn = false;
        this.sentenceAnimateOut = true;

        setTimeout(() => {
          // Move to the typing portion for this word
          this.gameState = 2;
          this.typingModeModel = "";

          // Animate the new question in
          setTimeout(() => {
            this.sentenceAnimateOut = false;
            this.sentenceAnimateIn = true;
            this.checkingAnswers = false;
          }, 300);
        }, 1000);
      } else {
        this.nextWord();
      }
    }, 1500);
  }

  checkTypingAnswer() {
    if(this.checkingAnswers) return;

    this.checkingAnswers = true;

    if(this.typingModeModel === this.words[this.currentWordIndex].word) {
      this.wordsCorrect[this.currentWordIndex] = true;
      this.typingCorrect = true;
    } else {
      this.typingIncorrect = true;
    }

    // Give the student a couple of seconds to see their wrong answer and go to next word.
    setTimeout(() => {
      this.nextWord();
    }, 1500);
  }

  selectSlot(slotIndex: number) {
    if(this.checkingAnswers) return;

    // If no phoneme from the bank is selected, play the sound of this bank slot and set it as selected
    if(this.selectedPhonemeIndex === -1) {
      // Deselect if we're clicking the slot that we already have selected.
      if(slotIndex === this.selectedSlotIndex) {
        this.selectedSlotIndex = -1;
        return;
      } else if(this.selectedSlotIndex !== -1) {
        // Move the phoneme in the selectedSlotIndex to the new slotIndex
        const tempPhoneme: SpellingWordPhoneme | undefined = this.correctPhonemeList[slotIndex];
        this.correctPhonemeList[slotIndex] = this.correctPhonemeList[this.selectedSlotIndex];
        this.correctPhonemeList[this.selectedSlotIndex] = { ...tempPhoneme, correct: false, incorrect: false } as PhonemeWithCorrect;
        this.selectedSlotIndex = -1;
        return;
      }
      // Play the sound for this slot
      if(!this.correctPhonemeList[slotIndex]?.phoneme) return;
      this.playPhonemeFromSlots(slotIndex);
      this.selectedSlotIndex = slotIndex;
      return;
    };

    // Drop the phoneme into the slot and remove from bank
    const tempPhoneme: SpellingWordPhoneme | undefined = this.correctPhonemeList[slotIndex];
    this.correctPhonemeList[slotIndex] = { ...this.phonemeBankList[this.selectedPhonemeIndex], correct: false, incorrect: false } as PhonemeWithCorrect;
    if(tempPhoneme !== undefined) {
      this.phonemeBankList.splice(this.selectedPhonemeIndex, 1, tempPhoneme);
    } else {
      this.phonemeBankList.splice(this.selectedPhonemeIndex, 1);
    }
    this.selectedPhonemeIndex = -1;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragStart(event: DragEvent, phonemeIndex: number, isSlot: boolean = false): void {
    if (event.dataTransfer) {
      const dragData = JSON.stringify({ index: phonemeIndex, isSlot });
      event.dataTransfer.setData('application/json', dragData);
    }
  }
  
  onDrop(event: DragEvent, slotIndex: number): void {
    event.preventDefault();
  
    const dragData = JSON.parse(event.dataTransfer?.getData('application/json') || '{}');
    const { index, isSlot } = dragData;
  
    if (isSlot) {
      const tempPhoneme = this.correctPhonemeList[slotIndex];
      this.correctPhonemeList[slotIndex] = { ...this.correctPhonemeList[index], correct: false, incorrect: false } as PhonemeWithCorrect;
      this.correctPhonemeList[index] = tempPhoneme;
    } else {
      const tempPhoneme: SpellingWordPhoneme | undefined = this.correctPhonemeList[slotIndex];
      this.correctPhonemeList[slotIndex] = { ...this.phonemeBankList[index], correct: false, incorrect: false } as PhonemeWithCorrect;
  
      if (tempPhoneme !== undefined) {
        this.phonemeBankList.splice(index, 1, tempPhoneme);
      } else {
        this.phonemeBankList.splice(index, 1);
      }
    }
  
    this.selectedPhonemeIndex = -1;
  }
  
  onDropToBank(event: DragEvent): void {
    event.preventDefault();
  
    const dragData = JSON.parse(event.dataTransfer?.getData('application/json') || '{}');
    const { index, isSlot } = dragData;
  
    if (isSlot) {
      const phonemeToReturn = this.correctPhonemeList[index];
      if (phonemeToReturn) {
        this.phonemeBankList.push(phonemeToReturn);
        this.correctPhonemeList[index] = undefined;
      }
    }
  }

  // Functions for speaking words and phonemes
  playWord() {
    this.speakableService.playSpeakableByKey(this.words[this.currentWordIndex].speechData?.word, this.words[this.currentWordIndex].word);
  }

  playPhonemeFromBank(phonemeIndex: number) {
    if(this.checkingAnswers) return;

    if(phonemeIndex === this.selectedPhonemeIndex) {
      this.selectedPhonemeIndex = -1;
      return;
    }
    
    const phoneme = this.phonemeBankList[phonemeIndex].phoneme;
    this.speakableService.getAndPlayPhoneme(phoneme);
    this.selectedPhonemeIndex = phonemeIndex;
  }

  playPhonemeFromSlots(slotIndex: number) {
    if(this.checkingAnswers) return;

    const slotData = this.correctPhonemeList[slotIndex];
    
    // Return if this slot doesn't have a phoneme in it yet.
    if(!slotData) return;

    const phoneme = slotData.phoneme;
    this.speakableService.getAndPlayPhoneme(phoneme);
    this.selectedSlotIndex = slotIndex;
  }
}
