import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GENERATING_SPEAKABLE_TEXT } from 'src/constants/shared';
import { LessonsService } from 'src/services/lessons.service';
import { SpeakableService } from 'src/services/speakable.service';
import { ToastsService } from 'src/services/toasts.service';
import { QuestionImage, SACQuestion } from 'src/types/question';

@Component({
  selector: 'app-select-and-change',
  templateUrl: './select-and-change.component.html',
  styleUrls: ['./select-and-change.component.scss']
})
export class SelectAndChangeComponent implements AfterContentInit, OnChanges {
  _imageUrls: string[] = [];

  @Input() question: SACQuestion;
  @Input() animateSentenceIn: boolean = false;
  
  @Output() answeredCorrectly = new EventEmitter<any>();
  @Output() answeredIncorrectly = new EventEmitter<any>();
  @Output() completedQuestion = new EventEmitter<any>();

  correctAnswers: number = 0;
  incorrectAnswers: number = 0;
  checkingAnswer: boolean = false;

  sentenceAnimateIn: boolean = false;
  sentenceAnimateOut: boolean = false;

  /**
   * As choices are chosen, this populates with wether they were correct or not.
   */
   choiceCorrectMap: {
    [choiceIndex: number]: boolean
  } = {};

  constructor(private speakableService: SpeakableService, private toastsService: ToastsService, private lessonsService: LessonsService) { }

  ngAfterContentInit(): void {
    this.sentenceAnimateIn = this.animateSentenceIn;

    if(this.question.data.images) {
      const imagePromises = this.question.data.images.map(async (image) => {
        const response = await this.lessonsService.getQuestionImage(image.key);
        return response.imageUrl;
      });

      Promise.all(imagePromises).then((urls) => {
          this._imageUrls = urls;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['animateSentenceIn'] && changes['animateSentenceIn'].currentValue) {
      this.sentenceAnimateIn = true;
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if(!this.speakableService.clickToSpeechActive) return;
    this.speakableService.playSpeakableByKey(url, text);
  }

  selectChangeWord(selectedWordIndex: number) {
    // Play the speakable and return if we're in clickToSpeech mode
    if(this.speakableService.clickToSpeechActive) {
      this.playSpeakable(this.question.data.words[selectedWordIndex].speechData?.text, this.question.data.words[selectedWordIndex].text);
      return;
    }

    const selectedWord = this.question.data.words[selectedWordIndex];

    if(this.checkingAnswer || this.choiceCorrectMap[selectedWordIndex] !== undefined) {
      return;
    }

    this.checkingAnswer = true;

    if(selectedWord.correctWord) {
      this.answeredCorrectly.emit();

      this.choiceCorrectMap[selectedWordIndex] = true;
      //selectedWord.word = selectedWord.corrected;
      this.correctAnswers++;

      //Early escape if not all correct words are selected yet.
      for (let i = 0; i < this.question.data.words.length; i++) {
        const word = this.question.data.words[i];
        if(word.correctWord && this.choiceCorrectMap[i] == undefined) {
          //TODO: After timer, indicate there is still more to be clicked?
          this.checkingAnswer = false;
          return;
        }
      }

      setTimeout(() => {
        this.sentenceAnimateOut = true;

        setTimeout(() => {
          this.completedQuestion.emit();
        }, 1000);
      }, 2500);
    } else {
      this.choiceCorrectMap[selectedWordIndex] = false;
      this.incorrectAnswers++;
      this.answeredIncorrectly.emit();
      this.checkingAnswer = false;
    }
  }

  getChoiceColor(index: number){
    const madeChoice = this.choiceCorrectMap[index];
    if(madeChoice !== undefined){
      if(madeChoice === true){
        return '#00D2A8'
      }else{
        return '#FF3162'
      }
    }else{
      return '';
    }
  }

  isPunctuation(word: string): boolean {
    return ".,;:!?".includes(word);
  }

}
