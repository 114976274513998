import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environment/environment';
import { SecureImagePipe } from 'src/pipes/secure-image.pipe';
import { SpeakableService } from 'src/services/speakable.service';
import { CurriculumLesson } from 'src/types/modules';
import { CosmittBorderedComponent } from '../c-bordered/c-bordered.component';
import { CosmittStarModule } from '../c-star/c-star.module';


@Component({
  selector: 'course-totem',
  templateUrl: './course-totem.component.html',
  styleUrl: './course-totem.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SecureImagePipe,
    CosmittStarModule,
    CosmittBorderedComponent
  ]
})
export class CourseTotemComponent implements OnChanges {
  @Input() lesson: CurriculumLesson;
  @Input() isActive: boolean = false;
  @Input() stars: number;
  @Input() grade: number;
  @Input() background: string;

  totemHeight: number = window.innerHeight * 0.54;
  baseApiUrl: string = environment.apiBaseUrl;
  calculatedMarginBottom: number = 0;

  @ViewChild('lessonInfo') lessonInfoRef!: ElementRef;

  constructor(private speakableService: SpeakableService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isActive']) {
      setTimeout(() => {
        this.calculateMarginBottom();
      }, 10);
    }
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(key: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if (!this.speakableService.clickToSpeechActive) return;
    this.speakableService.playSpeakableByKey(text, text); // TODO: Have a better way to handle this. I know it will just has the text and get the pre-generated text from S3 due to our file naming, but this isn't the best way to do things.
  }

  calculateMarginBottom() {
    const cBorderedHeight = this.lessonInfoRef.nativeElement.clientHeight;

    // Calculate the margin-bottom using your formula
    this.calculatedMarginBottom =
      (this.totemHeight -
        cBorderedHeight) / 2 +
      this.totemHeight * 0.167;
  }
}
