import { Component, EventEmitter, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LessonsService } from "src/services/lessons.service";
import { ToastsService } from "src/services/toasts.service";
import { UsersService } from "src/services/users.service";
import { Lesson } from "src/types/modules";
import { Question, QuestionType, validateQuestion, verifyQuestion } from "src/types/question";
import { User } from "src/types/users";


@Component({
    selector: 'admin-lesson-page',
    templateUrl: './lesson.component.html',
    styleUrls: ['./lesson.component.scss']
})
export class AdminLessonPage implements OnInit {

    user: User | null;

    lesson: Lesson;
    lessonId: string;
    currentQuestion: Question;
    currentQuestionIndex: number = 0;

    questionsRandomized: boolean = false; // If true, grabs random question, else goes linearly
    questionsIndexAnswered: number[] = []; // An array of the index's of questions that have already been answered by the user

    introOpen: boolean = true;
    introStartText: string = "Start lesson";

    userIsAdmin: boolean = false;

    // Variables for editing and creating questions
    creating: boolean = false;
    questionEditModalOpen: boolean = false;
    questionSave$: EventEmitter<void> = new EventEmitter<void>();
    questionCloseBackground$: EventEmitter<void> = new EventEmitter<void>();

    // Variables for question generation
    questionsGenerationModalOpen: boolean = false;
    questionGenerationJSON: string = "";
    questionGenerationType: QuestionType = "selectTheWord";

    gameModeOptions: { label: string, value: QuestionType }[] = [
        { label: 'Select the Word', value: 'selectTheWord' },
        { label: 'Select and Change', value: 'selectAndChange' },
        { label: 'Fill in the Blank', value: 'fillInTheBlank' },
        { label: 'Multiple Choice', value: 'multipleChoice' },
        { label: 'Grouping', value: 'grouping' },
        { label: 'Ranking', value: 'ranking' },
        { label: 'Unscramble', value: 'unscramble' },
        { label: 'Spelling', value: 'spelling' },
    ];

    constructor(private usersService: UsersService, private lessonsService: LessonsService, private toastsService: ToastsService, private route: ActivatedRoute) {
        this.usersService.setUserFocusMode(true);
        this.questionCloseBackground$.subscribe(() => {
            this.questionEditModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                
            }, 500);
        });
    }

    async ngOnInit() {
        // Get the user
        this.user = await this.usersService.getUser();

        if(this.user?.roles.includes('admin')) this.userIsAdmin = true;

        if(!this.user) {
            // Get mad that we don't have the user
            console.error("No user was found.")
            return;
        }

        // Get lessonId from the route params
        this.route.params.subscribe(async params => {
            this.lessonId = params['lessonId'];

            this.lesson = await this.lessonsService.getSingleLesson(this.lessonId);
        });
    }
    
    openQuestionGenerationModal() {
        this.questionsGenerationModalOpen = true;
    }

    async generateQuestions() {
        // Verification of question JSON data
        if(!this.questionGenerationJSON) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid format',
                description: "You must provide JSON data to generate questions.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }

        try {
            const parsedQuestions = JSON.parse(this.questionGenerationJSON);
            const updatedLesson = await this.lessonsService.addGeneratedQuestionsToLesson(this.lesson._id, this.questionGenerationType, parsedQuestions);
            this.lesson = updatedLesson;
            this.questionsGenerationModalOpen = false;
            this.currentQuestion = this.lesson.questions[0];
        } catch(error) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid format',
                description: "You must provide valid JSON data to generate questions.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    questionBackgroundClose() {
        this.questionEditModalOpen = false;
        // Wait for animation to finish, then set currentQuestion to null
        setTimeout(() => {
            // this.resetAllValues();
        }, 500);
    };

    startLesson(): void {
        if(!this.user) return;

        this.introOpen = false;
        this.currentQuestion = this.lesson.questions[0];
    }

    answeredCorrectly() {

    }

    answeredIncorrectly() {
        
    }

    completedQuestion() {
        this.currentQuestionIndex++;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
    }

    nextQuestion() {
        this.currentQuestionIndex++;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
    }

    gotoQuestionByIndex(index: number) {
        if(index === this.currentQuestionIndex) {
            this.questionEditModalOpen = true;
        }
        this.currentQuestionIndex = index;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
        this.creating = false;
    }

    openCreateQuesiton() {
        this.currentQuestion = {
            type: this.lesson.defaultType || 'selectTheWord',
            data: {
                instruction: this.lesson.defaultInstruction || ''
            }
        } as any;

        this.creating = true;
        this.currentQuestionIndex = -1;
        this.questionEditModalOpen = true;
    }

    async createQuestion() {
        try {
            // Will throw error if question is not valid
            verifyQuestion(this.currentQuestion, this.currentQuestion.type);
            
            const updatedLesson = await this.lessonsService.addQuestionToLesson(this.lessonId, this.currentQuestion);
            this.lesson.questions = updatedLesson.questions;

            this.questionEditModalOpen = false;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid Question',
                description: error.message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }

    async saveQuestion() {
        // Check if we're creating and return out of this function if so.
        if(this.creating) {
            this.createQuestion();
            return;
        }

        // Request the API to update the lessons question
        try {
            if(!this.currentQuestion) return;
            try {
                validateQuestion(this.currentQuestion);
            } catch(error: any) {
                this.toastsService.addToast({
                    type: 'error',
                    title: 'Question Error',
                    description: error.message,
                    duration: 3000, // Auto-dismiss after 3 seconds
                });
                return;
            }

            if(this.currentQuestionIndex === null) return;
            this.lesson.questions[this.currentQuestionIndex] = this.currentQuestion;
            await this.lessonsService.updateLesson(this.lesson._id, { questions: this.lesson.questions });
            this.questionEditModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                // this.resetAllValues();
            }, 500);
            this.toastsService.addToast({
                type: 'success',
                title: 'Question updated',
                description: "Successfully updated question.",
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: message,
                duration: 3000, // Auto-dismiss after 3 seconds
            });
        }
    }
}