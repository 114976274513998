import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Toast {
    id: string;
    type: 'default' | 'success' | 'warning' | 'error' | 'info';
    title?: string;
    description?: string;
    actions?: { label: string; callback: () => void }[];
    duration?: number; // in milliseconds
    persistent?: boolean; // True if it should wait for a condition to close
    removing?: boolean; // True if the toast is in the process of being removed
}

@Injectable({ providedIn: 'root' })
export class ToastsService {
    private toastsSubject = new BehaviorSubject<Toast[]>([]);
    toasts$ = this.toastsSubject.asObservable();

    addToast(toast: Omit<Toast, 'id'>) {
        const id = Math.random().toString(36).substring(2, 15); // Generate unique ID
        const newToast = { ...toast, id };
        const currentToasts = this.toastsSubject.value;
        this.toastsSubject.next([...currentToasts, newToast]);

        if (newToast.duration && !newToast.persistent) {
            setTimeout(() => this.triggerRemoveToast(id), newToast.duration);
        }

        return id; // Return the generated ID
    }

    private triggerRemoveToast(id: string) {
        const currentToasts = this.toastsSubject.value;
        const toastIndex = currentToasts.findIndex((toast) => toast.id === id);
        if (toastIndex > -1) {
            currentToasts[toastIndex].removing = true;
            this.toastsSubject.next([...currentToasts]);

            // Wait for the fade-out animation to complete before removing
            setTimeout(() => this.removeToast(id), 300); // Match fade-out duration
        }
    }

    removeToast(id: string) {
        const updatedToasts = this.toastsSubject.value.filter(toast => toast.id !== id);
        this.toastsSubject.next(updatedToasts);
    }

    clearAllToasts() {
        this.toastsSubject.next([]);
    }
}